<template>
  <select v-model="selectedState" @change="stateChanged" class="state-picker">
    <option
      v-for="(state, $idx) in stateOptions"
      :value="state"
      :key="$idx"
    >
      {{ state.name }}
    </option>
  </select>
</template>

<script>
import { Constants } from "Services/Constants";
export default {
  props: ["value"],
  data() {
    return {
      stateOptions: Constants.states,
      selectedState: this.value,
    };
  },
  methods: {
    stateChanged() {
      this.$emit("input", this.selectedState);
      this.$emit("change", this.selectedState)
    },
  },
};
</script>

